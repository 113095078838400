<template>
  <div>
    <Breadcrumbs />
    <section class="forgot-password-page">
      <b-container>
        <b-row class="forgot-password-wrap">
          <b-col md="12" lg="12">
            <div class="title-wrap">
              <div class="form-title">{{ $t("password_forgot") }}</div>
              <p>
                {{ $t("forgot_password_text") }}
              </p>
            </div>
            <b-form-group
              :label="$t('form_email') + '*'"
              label-for="email"
              :description="description"
            >
              <b-form-input
                id="email"
                v-model="email"
                type="text"
                :state="emailState"
                aria-describedby="email-feedback"
              ></b-form-input>
              <b-form-invalid-feedback id="email-feedback">
                {{ emailError }}
              </b-form-invalid-feedback>
            </b-form-group>
            <div>
              <!-- <b-link class="back" href="/login">{{ $t("go_back") }}</b-link> -->
              <div class="btn-holder">
                <b-button @click="formSubmit" variant="primary">{{
                  $t("send_link")
                }}</b-button>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>

<script>
import Breadcrumbs from "@/base/core/components/common/Breadcrumbs";
import { forgotPassword } from "@storefront/core/data-resolver/user";
export default {
  name: "ForgotPassword",
  components: {
    Breadcrumbs,
  },
  mounted() {
    const bcrumb = { current: this.$t("password_forgot"), routes: [] };
    this.$store.commit("breadcrumbs/set", bcrumb);
  },
  metaInfo() {
    return {
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        { name: "title", content: this.$t("password_forgot") },
        { name: "keywords", content: this.$t("password_forgot") },
        { name: "description", content: this.$t("password_forgot") },
      ],
      title: this.$t("password_forgot"),
    };
  },
  data() {
    return {
      email: "",
      emailState: null,
      emailError: "",
      description: "",
      emailReg:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
    };
  },
  methods: {
    async formSubmit() {
      if (this.validateEmail()) {
        const retval = await forgotPassword(this.email);
        if (retval == true) {
          this.description = this.$t("reset_password_email_has_been_sent");
        } else {
          this.description = this.$t(
            "reset_password_email_could_not_been_sent"
          );
        }
      }
    },
    validateEmail() {
      if (this.email == "") {
        this.emailState = false;
        this.emailError = this.$t("email_is_required_field");
        return false;
      }
      if (!this.emailReg.test(this.email)) {
        this.emailState = false;
        this.emailError = this.$t("email_is_invalid_field");
        return false;
      }
      this.emailState = true;
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
