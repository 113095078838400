<template>
  <div>
    <Breadcrumbs />
    <section class="forgot-password-page">
      <b-container>
        <b-row class="forgot-password-wrap">
          <b-col md="12" lg="12">
            <div class="title-wrap">
              <div class="form-title">{{ $t("password_reset") }}</div>
              <p>
                {{ $t("reset_password_text") }}
              </p>
            </div>
            <b-form-group
              class="account-inputs"
              :label="$t('form_email') + '*'"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="email"
                type="text"
                :state="emailState"
                class="account-inputs"
                aria-describedby="email-feedback"
              ></b-form-input>
              <b-form-invalid-feedback id="email-feedback">
                {{ emailError }}
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group
              class="account-inputs"
              :label="$t('form_token') + '*'"
              label-for="token"
            >
              <b-form-input
                id="token"
                v-model="token"
                type="text"
                :state="tokenState"
                aria-describedby="token-feedback"
              ></b-form-input>
              <b-form-invalid-feedback id="token-feedback">
                {{ tokenError }}
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group
              class="account-inputs"
              id="password-group"
              :label="$t('form_password') + '*'"
              label-for="password"
            >
              <b-form-input
                id="password"
                v-model="password"
                type="password"
                :state="passwordState"
                aria-describedby="password-feedback"
              ></b-form-input>
              <b-form-invalid-feedback id="password-feedback">
                {{ passwordError }}
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group
              class="account-inputs"
              id="password_confirm-group"
              :label="$t('form_password_confirmation') + '*'"
              label-for="password_confirm"
            >
              <b-form-input
                id="password_confirm"
                v-model="password_confirm"
                type="password"
                :state="password_confirmState"
                aria-describedby="password_confirm-feedback"
              ></b-form-input>
              <b-form-invalid-feedback id="password_confirm-feedback">
                {{ password_confirmError }}
              </b-form-invalid-feedback>
            </b-form-group>
            <div>{{ description }}</div>
            <div>
              <!-- <b-link class="back" href="/login">{{ $t("go_back") }}</b-link> -->
              <div class="btn-holder">
                <b-button @click="formSubmit" variant="primary">{{
                  $t("reset_my_password")
                }}</b-button>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>

<script>
import Breadcrumbs from "@/base/core/components/common/Breadcrumbs";
import { resetPassword } from "@storefront/core/data-resolver/user";
export default {
  name: "ResetPassword",
  components: { Breadcrumbs },
  mounted() {
    const bcrumb = { current: this.$t("password_reset"), routes: [] };
    this.$store.commit("breadcrumbs/set", bcrumb);
  },
  metaInfo() {
    return {
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        { name: "title", content: this.$t("password_reset") },
        { name: "keywords", content: this.$t("password_reset") },
        { name: "description", content: this.$t("password_reset") },
      ],
      title: this.$t("password_reset"),
    };
  },
  data() {
    return {
      email: "",
      emailState: null,
      emailError: "",
      description: "",
      password: "",
      passwordState: null,
      passwordError: "",
      password_confirm: "",
      password_confirmState: null,
      password_confirmError: "",
      token: this.$route.query.token,
      tokenState: null,
      tokenError: "",
      emailReg:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
    };
  },
  methods: {
    async formSubmit() {
      if (
        this.validateEmail() &&
        this.validatePassword() &&
        this.validateConfirmationPassword() &&
        this.validateToken()
      ) {
        const retval = await resetPassword(
          this.email,
          this.token,
          this.password
        );
        if (retval == true) {
          this.description = this.$t("password_resetted");
        } else {
          this.description = this.$t("password_not_resetted");
        }
      }
    },
    validatePassword() {
      if (this.password.length < 8) {
        this.passwordState = false;
        this.passwordError = this.$t("password_needs");
        return false;
      } else if (this.password.match(/[a-z]/) == null) {
        this.passwordState = false;
        this.passwordError = this.$t("password_needs");
        return false;
      } else if (
        this.password.match(/[!"#$%&'()*+,-.:;<=>?@[\]^_`{|}~]/) == null
      ) {
        this.passwordState = false;
        this.passwordError = this.$t("password_needs");
        return false;
      } else if (this.password.match(/[A-Z]/) == null) {
        this.passwordState = false;
        this.passwordError = this.$t("password_needs");
        return false;
      } else if (this.password.match(/\d/) == null) {
        this.passwordState = false;
        this.passwordError = this.$t("password_needs");
        return false;
      } else {
        this.passwordState = true;
        return true;
      }
    },
    validateConfirmationPassword() {
      if (this.password != this.password_confirm) {
        this.password_confirmState = false;
        this.password_confirmError = this.$t("password_confirm_not_equal");
        return false;
      } else {
        this.password_confirmState = true;
        return true;
      }
    },
    validateToken() {
      if (this.token == "") {
        this.tokenState = false;
        this.tokenError = this.$t("token_not_filled");
        return false;
      } else {
        this.tokenState = true;
        return true;
      }
    },
    validateEmail() {
      if (this.email == "") {
        this.emailState = false;
        this.emailError = this.$t("email_is_required_field");
        return false;
      }
      if (!this.emailReg.test(this.email)) {
        this.emailState = false;
        this.emailError = this.$t("email_is_invalid_field");
        return false;
      }
      this.emailState = true;
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
